body {
  background-color: #ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.circle {
  border-radius: 50%;
}

.center {
  margin: 0 auto;
}

.photo-section {
  margin-top: 10px;
}

.photo-section figure {
  margin-bottom: 10px;
}

.info a {
  color: #000000 !important;
  border-bottom: 0px !important;
}

ul.no-point li {
  list-style-type: none;
}

#contact {
  padding-top: 10px;
}

#contact a {
  color: inherit;
  padding-right: 10px;
  padding-left: 10px;
}

.box {
  transition: all 0.1s linear;
}

.box:hover {
  -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
}
